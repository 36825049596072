import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppConfiguration } from '@config/app-configuration';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@services/authentication.service';
import { IdmRestService } from '@services/idmrest.service';
import { LoadingScreenService } from '@services/loading-screen.service';
import { PostLoginResponseEvent, SelectedCardEvent } from '@services/message-bus/message-bus-events';
import { MessageBusService } from '@services/message-bus/message-bus.service';
import { RestService } from '@services/rest.service';
import { ThemeService } from '@services/theme.service';
import { AuthConfig, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { ConnectionService } from 'ng-connection-service';
declare const gtag: Function;
@Component({
  selector: 'smione-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'smione';
  authConfig: AuthConfig;
  languages = ['en', 'es'];
  idleState = 'Not started.';
  timedOut = false;
  firstCard = null;
  lastPing?: Date = null;
  isConnected :boolean = true;
  //@HostListener('contextmenu', ['$event'])
  //onRightClick(event) {   
  //  event.preventDefault();
  //}

  getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
  };

  constructor(
    private oauthService: OAuthService,
    private authStorage: OAuthStorage,
    private translate: TranslateService,
    private appConfig: AppConfiguration,
    private authenticationService: AuthenticationService,
    private router: Router,
    private restService: RestService,
    private messageBus: MessageBusService,
    private themeService: ThemeService,
    private idle: Idle, 
    private keepalive: Keepalive,
    private connectionService: ConnectionService
    ) {
      this.addGAScript();
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          console.log('urlAfterRedirects',event.urlAfterRedirects);
          console.log('googleAnalyticsId',this.appConfig.googleAnalyticsId);
          gtag('config', this.appConfig.googleAnalyticsId, { 'page_path': event.urlAfterRedirects });
        }      
      })
      this.restService.preventBrowserBack();
      this.restService.setDefaultLanguage();
     var urlNew = location.pathname.toLowerCase();
    let query = this.getQueryStringParams(window.location.search);
    console.log('QueryString', query); 
    
    if(query && Object.keys(query).length != 0 && !query.hasOwnProperty('code') && !query.hasOwnProperty('session_state') && !query.hasOwnProperty('state')){
      localStorage.setItem('query', JSON.stringify(query));
    }
    if(urlNew.substring(0,4) =="/new")
    {
      urlNew = urlNew.substring(4)
    }
    else
      urlNew = location.pathname;
    if(urlNew == '/deposit'){
      localStorage.setItem('target', 'DD');
    }else if(urlNew == '/fees'){
      localStorage.setItem('target', 'FEES');
    }
    else if(urlNew == '/alert'){
      localStorage.setItem('target', 'ALERT');
    }
    else if(urlNew == '/setting'){
      localStorage.setItem('target', 'SETTING');
    }
    var url ="";
    if(urlNew != '' && urlNew != '/' && urlNew != '/login')
    { 
      url = urlNew;
      localStorage.setItem("pathName",url);
    }
    
    
    if (!((url=='/trouble-login')|| (url=='/card-protection')|| (url=='/download-app') || (url =='/hrtransactions') || (url=='/download')||  (url=='/signupq') || (url=='/register')|| (url=='/enroll') || (url=='/cip') || (url =='/secure-redirect') || (url =='/additional-verify')))
    { 
       if(( url.toLowerCase().substring(0, 8)==('/smione/') || localStorage.getItem('target') || url =="/" || url =="" || url.toLowerCase().includes('pending-login')))
      {     
        if(!sessionStorage.getItem("token") || (sessionStorage.getItem("token") == "null"))
        {
           if( (!localStorage.getItem('target') || localStorage.getItem('target') =="") &&
            urlNew != '' && 
            urlNew != '/' && 
            urlNew != '/login'           
            ){
              localStorage.setItem('target', urlNew);
            }
          this.configureOAuth();
        }
        else
        {
          if(localStorage.getItem('data')){
            let data = JSON.parse(localStorage.getItem('data'));
            this.translate.setDefaultLang(data.Profile.PreferredLanguage);
            this.translate.use(data.Profile.PreferredLanguage);
            this.messageBus.emit(new PostLoginResponseEvent(data));
            this.messageBus.emit(new SelectedCardEvent(data.Cards[0]));
            this.messageBus.on(PostLoginResponseEvent, (event) => { 
             
              if(event.payload.Cards.length > 0){
                this.firstCard = event.payload.Cards[0]; 
              } 
              // const inactiveCards = event.payload.Cards.filter(f => f.StatusCode === 'INA' && f.SubStatusCode === 'ACP');                  
              if (this.firstCard && this.firstCard.StatusCode === 'INA' && this.firstCard.SubStatusCode === 'ACP') 
                this.router.navigate(['/pending-login']); 
              else if((url =="/" || url ==""  || (url.toLowerCase().substring(0, 8)!=('/smione/')))&& (!localStorage.getItem('target')))
                this.router.navigate(['/smione/dashboard']);
            });
          }
          else
            this.configureOAuth();
        }
      }
      else
        this.router.navigate(['/error']);
    } 
    const logoutTimeInSeconds = appConfig.logoutTime * 60;
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(logoutTimeInSeconds);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(2);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });
    
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.logout();
    });
    
    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState);
    });
    
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    // keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.reset();

    if (this.authenticationService.getToken()) {
      idle.watch()
      this.timedOut = false;
    } else {
      idle.stop();
    }

    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) { console.log('ONLINE'); }
      else { console.log('OFFLINE'); }
    })

  }

  /** Add Google Analytics Script Dynamically */
  addGAScript() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.appConfig.googleAnalyticsId;
    document.head.prepend(gtagScript);
    /** Disable automatic page view hit to fix duplicate page view count  **/
    gtag('config', this.appConfig.googleAnalyticsId, { send_page_view: false });
  }

  ngOnInit(){
    this.themeService.SetThemeInitially()
  }

  reset() {  
    this.idle.watch();
    this.timedOut = false;
  }

  logout(){
    sessionStorage.removeItem("token");
    console.log("Logout");
    if( this.oauthService.issuer)
      this.oauthService.logOut();
    let ADM_logout_url = this.restService.generateIDMLogoutUrl();
    if(localStorage.getItem("DeviceID")&& localStorage.getItem("DeviceID")!="")
    {
      const DeviceID = localStorage.getItem("DeviceID");
      const IsTrustedDevice = localStorage.getItem("IsTrustedDevice");
      localStorage.clear();
      localStorage.setItem("DeviceID", DeviceID);
      localStorage.setItem("IsTrustedDevice", IsTrustedDevice);
    }
    else
      localStorage.clear();
    sessionStorage.removeItem('alerts');
    window.open(ADM_logout_url, "_self");
  }


  private configureOAuth() {  
   
    this.authConfig = {
      issuer: this.appConfig.idmCoreServiceUrl,  
      redirectUri: this.appConfig.redirect_uri,    
      clientId: this.appConfig.appId['web'],
      responseType: 'code',
      scope: 'read write',
      showDebugInformation: true,
      dummyClientSecret: 'secret'
    };
       
    this.oauthService.configure(this.authConfig);
    
    let additionalState = '';
    let params = {
      'usc':this.appConfig.usc,
      'avn':this.appConfig.version
    };
    return this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {     
      if (!this.oauthService.hasValidIdToken() || !this.authStorage.getItem('access_token')) {  
        this.oauthService.logOut();     
        this.oauthService.initCodeFlow(additionalState, params);  
      } 
      else
      {
        this.authenticationService.setToken(this.authStorage.getItem('access_token')); 
        sessionStorage.setItem("token",this.authStorage.getItem('access_token')) ;
        this.idle.watch()
        this.timedOut = false; 
        this.authStorage.removeItem('access_token')  ; 
        this.router.navigate([ '/login' ]);
      }
    });
  }

  
}
