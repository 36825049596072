import { Component, HostListener, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from '@services/rest.service';
import { Card } from '@models/post-login.model';
import { MessageBusService } from '@services/message-bus/message-bus.service';
import { AlertChangeEvent, SelectedCardEvent } from '@services/message-bus/message-bus-events';
import { DashboardService } from './../../../pages/dashboard/dashboard.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PostLoginResponseEvent } from '@services/message-bus/message-bus-events';
import { AppConfiguration } from '@config/app-configuration';
import { WarningsComponent } from '@common/warnings/warnings.component';
import { OldWebsiteComponent } from '@common/old-website/old-website.component';
import { TranslateService } from '@ngx-translate/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { AnalyticsService } from '@services/analytics.service';
import { GlobalService } from '@services/global.service';
@Component({
  selector: 'smione-smione-header',
  templateUrl: './smione-header.component.html',
  styleUrls: ['./smione-header.component.scss']
})
export class SmioneHeaderComponent implements OnInit {

  AllowPayPerks:boolean = false;
  public selectedCard: Card = {} as Card;
  IsNewWebsiteAvailable : boolean = false;
  screenHeight: number;
  screenWidth: number;
  classNavmenu:boolean = false;
  public toggleField() {
    this.analyticsService.gtag(this.componentName, 'toggleField');
    this.classNavmenu = !this.classNavmenu; 
  }
  cardsBtn: boolean = false;
  subscription: any;
  alerts :any;
  unreadCount :number = 0;
  showSavings :boolean = false;
  showMyContainer: boolean = false;
  menuOpen: boolean = false;
  menuBtnClick: boolean = false;
  isPopUpOpen :boolean = false;
  public cards;
  @ViewChild('menuTrigger') matMenuTrigger: MatMenuTrigger;
  componentName : string = 'SmioneHeader';
  shippingData;
  navDetails = {
    currentStep: "" 
  };
  constructor(
    private appConfig: AppConfiguration,
    private router: Router,
    private restService: RestService, 
    public messageBus: MessageBusService,
    private dashboardService: DashboardService,
    private dialog: MatDialog,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<OldWebsiteComponent>,
    public translate: TranslateService,
    private analyticsService: AnalyticsService,
    private globalService: GlobalService
  ) { 
    this.restService.menuEmitter.subscribe(this.toggleCardsMenu.bind(this));   
    this.renderer.listen('window', 'click', (e: Event) => {

      if(this.screenWidth<769){      
        if (!this.menuBtnClick) {
          this.menuOpen = false;
        }
        this.menuBtnClick = false;
      }

      else{ this.menuOpen = true;}

    });
    this.messageBus.on(SelectedCardEvent, (event) => {  
      this.selectedCard = event.payload;
      this.AllowPayPerks = this.selectedCard.AllowPayPerks;      
      this.IsNewWebsiteAvailable = this.selectedCard.IsNewWebsiteAvailable;  
      //this.IsNewWebsiteAvailable  = true; 
    });
 
    if(this.selectedCard && this.selectedCard.CardKey){
      this.getAlerts(this.selectedCard.CardKey);
    }
    this.subscription = this.messageBus.getCard()
    .subscribe(item => this.selecteCardValue(item));    
    if (this.selectedCard.AllowSavings) {
      this.messageBus.on(PostLoginResponseEvent, (event) => {
        const savingCards = event.payload.Cards.filter(f => f.AccountTypeBit63 === 32);
        if (savingCards && savingCards.length) { this.showSavings = true; }
        else { this.showSavings = false; }
      });
    }
    this.restService.getReloadAlertsStatus().subscribe(status=>{
      if(status){
        this.getAlerts(this.selectedCard.CardKey);
      }
    })
    this.getScreenSize();  
  }

  openMenu(event: MouseEvent, viewChild: MatMenuTrigger) {
    //event.preventDefault();
    viewChild.openMenu();
  }

  @HostListener('window:resize', ['$event'])

  getScreenSize(event?) {   
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
        if(this.screenWidth>768)
          this.menuOpen = true;
        else
          this.menuOpen = false;
  }

  toggleMenu() {
    this.analyticsService.gtag(this.componentName, 'toggleMenu');
    this.menuOpen = !this.menuOpen;
  }

  preventCloseOnClick() {
    this.analyticsService.gtag(this.componentName, 'preventCloseOnClick');
    this.menuBtnClick = true;
  }

  toggleCardsMenu(){
    this.matMenuTrigger.openMenu();
  }

  ngOnInit() {
    $(document).on("contextmenu",  function() {        
      //return false;
    }); 

    this.messageBus.on(PostLoginResponseEvent, (event) => { 
      this.cards = event.payload.Cards.filter(f => f.AccountTypeBit63 != 32);      
    });
    this.messageBus.on(SelectedCardEvent, (event) => { 
      this.selectedCard = event.payload;       
     });
    this.restService.isAlertChange.subscribe(res => {
      let alertUnreadCount = JSON.parse(sessionStorage.getItem("alerts"));
      if(alertUnreadCount){
        this.unreadCount = alertUnreadCount.UnreadCount;
      }
    })
   
  }

  getCardDesign() {
    
    this.globalService.getCardDesign(this);

  }

  setCard(card:Card) {
    this.selectedCard = card;
    this.messageBus.emit(new SelectedCardEvent(card));
    this.messageBus.emitCard(card);  
  }

  selecteCardValue(card: Card) {   
    this.selectedCard = card;  
    this.AllowPayPerks = this.selectedCard.AllowPayPerks;   
    this.IsNewWebsiteAvailable = this.selectedCard.IsNewWebsiteAvailable; 
    this.getAlerts(card.CardKey);
   
    if(this.selectedCard.AllowSavings){ 
      this.messageBus.on(PostLoginResponseEvent, (event) => { 
        const savingCards = event.payload.Cards.filter(f => f.AccountTypeBit63 === 32);
        if(savingCards && savingCards.length){ this.showSavings = true; }
        else{ this.showSavings = false; }
      });
    }   
  }
 
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getAlerts(CardKey){
    if (sessionStorage.getItem("alerts") && sessionStorage.getItem("alerts")!="null")
    {
      var res = JSON.parse(sessionStorage.getItem("alerts"));
      if(res.card == this.selectedCard.CardKey)
      {
        this.alerts = res.Alerts;
        this.unreadCount = res.UnreadCount;
        let warnings = [];
        this.alerts['Items'].forEach((alert)=>{
          if(alert.Content)
            alert.Content = alert.Content.replace(/‑/g, '-');
          if(alert.AlertTypeCode == 'WRN' && alert.Unread){
            warnings.push(alert);
          }
        });
        if(warnings.length){ this.showWarings(warnings); }
      }
      else
        this.getAlertsFromAPI(CardKey);
    }
    else
    {
      this.getAlertsFromAPI(CardKey);
    }
  }

  getAlertsFromAPI(CardKey)
  {
    this.dashboardService.getAlerts(CardKey).subscribe(
      (res)=>{
        this.messageBus.emit(new AlertChangeEvent(JSON.stringify(res)));
        res.card = this.selectedCard.CardKey;
        sessionStorage.setItem("alerts", JSON.stringify(res));        
        this.alerts = res.Alerts;
        this.unreadCount = res.UnreadCount;
        let warnings = [];
        this.alerts['Items'].forEach((alert)=>{
          if(alert.Content)
            alert.Content = alert.Content.replace(/‑/g, '-');
          if(alert.AlertTypeCode == 'WRN' && alert.Unread){
            warnings.push(alert);
          }
        });
        if(warnings.length){ this.showWarings(warnings); }
      }
    );
  }


  makeAlertRead(){
    this.analyticsService.gtag(this.componentName, 'makeAlertRead');
    // if(this.alerts && this.alerts['Items']){
    //   let unreadAlerts = [];
    //   for (let i in this.alerts['Items']) {
    //     if(this.alerts['Items'][i]['Unread'] == true){  unreadAlerts.push(this.alerts['Items'][i]['ID']); }
    //   }
    //   if(unreadAlerts.length){
    //     debugger;
    //     this.dashboardService.makeAlertsRead(this.selectedCard.CardKey, { cardKey: this.selectedCard.CardKey, AlertIds: unreadAlerts}).subscribe(
    //       (res)=>{
    //         // this.changeUnreadCount(unreadAlerts.length);
    //         this.router.navigate(['smione','dashboard','notification']);
    //         if(this.selectedCard && this.selectedCard.CardKey){
    //           this.getAlerts(this.selectedCard.CardKey);
    //         }
    //       },
    //       (error)=>{
    //         console.error(error);
    //       },
    //     );
    //   }else{ this.router.navigate(['smione','dashboard','notification']); }
    // }else{ this.router.navigate(['smione','dashboard','notification']); }

    this.router.navigate(['smione','dashboard','notification']);

  }

  changeUnreadCount(unreadAlertsCount){
    let alerts = JSON.parse(sessionStorage.getItem("alerts"));
    if(+alerts.UnreadCount){
      alerts.UnreadCount = +alerts.UnreadCount - unreadAlertsCount;
    }
    sessionStorage.setItem("alerts", JSON.stringify(alerts));
    this.restService.isAlertChange.next(true); 
  }

  logout(){
    this.analyticsService.gtag(this.componentName, 'logout');
    this.restService.generateNewIDMLogoutUrl(); 
  }

  showWarings(warnings){
    var url = location.pathname;
    if((url.includes("smione/dashboard")) && (this.selectedCard.WarningShown== undefined || this.selectedCard.WarningShown == false))
    {
      this.dialog.open(WarningsComponent, {
        disableClose : true,
        data : warnings
      })
      .afterClosed().subscribe(result => {
        if(result){ this.getAlerts(this.selectedCard.CardKey); }
      });
    }
  }

  naviagteToDashboard(){ 
    this.analyticsService.gtag(this.componentName, 'naviagteToDashboard');
    this.router.navigate(['smione','dashboard']); 
  }

  clickEvent(){
      this.cardsBtn = !this.cardsBtn;       
  }

  goToOldWebsite(event:any)
  { 
    this.analyticsService.gtag(this.componentName, 'goToOldWebsite');          
    event.target.blur();
    return;

    if (this.isPopUpOpen) {
      return;
    }
    this.isPopUpOpen = true;
    const dialogRef = this.dialog.open(OldWebsiteComponent, { 
      disableClose : true,
      data : { Card : this.selectedCard } 
    })  
    .afterClosed().subscribe(result => {  
      this.isPopUpOpen = false;    
     });  
  }

  orderNewCard() { 
    this.router.navigate(['/smione/dashboard/order-new-card']); 
  }
  
}
