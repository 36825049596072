import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppConfiguration } from '@config/app-configuration';
import { RestService } from '@services/rest.service';
import { IdmRestService } from '@services/idmrest.service';
import { IDashboardResponse } from './dashboard.model';
import { map } from 'rxjs/operators';
import { Card } from '@models/post-login.model';
import {ItemRequest} from './models/item-request';
import {Item} from './models/item';
import { SpendLoadResponse } from './models/activity-analysis.model';
import { IESignAcceptRequest } from './models/esign-agreement.model';
@Injectable({ providedIn: 'root' })
export class DashboardService {

  public payperksCache={};

  constructor(
    private restService: RestService, 
    private httpClient: HttpClient,
    private appConfig: AppConfiguration,
    private idmrestService: IdmRestService,
    ) {
  }

  public getDashboardData(cardKey: number, fromDate: string, toDate: string): Observable<IDashboardResponse> {

    const httpParams = new HttpParams()
    .set('altype', '0')
    .set('cardKey', cardKey.toString())
    .set('ial', '1')
    .set('ipt', 'true')
    .set('isa', '1')
    .set('isl', '1')
    .set('itr', '1')
    .set('ptrRows', '5')
    .set('safdt', fromDate)
    .set('satdt', toDate)
    .set('slfdt', fromDate)
    .set('sltdt', toDate)
    .set('trRows', '5')
    .set('trep', 'true');

    return this.restService.get<IDashboardResponse>('/api/cardholders/dashboard', null, httpParams);
  }

  public getCardDesign(ids: number): Observable<any> {
    const httpParams = new HttpParams().set('ids', ids.toString());
    return this.restService.get<any>('/api/cardholders/cards/designs', null, httpParams);
  }

  public getReportStolen(cardKey: number): Observable<any> {
    const httpParams = new HttpParams();    
    let url = `/api/cardholders/cards/${cardKey.toString()}/lost-stolen`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public getReplace(cardKey: number): Observable<any> {
    const httpParams = new HttpParams() 
    .set('cardKey', cardKey.toString())
    .set('contentType', 'ReplaceCard')   ;       
    let url = "/api/cardholders/policy/latest/";
    return this.restService.get<any>(url, null, httpParams);
  }

  public getShippingId(cardKey: number): Observable<any> {
    const httpParams = new HttpParams() 
    .set('cardKey', cardKey.toString());        
    let url = "/api/masters/programs/shipping-types";
    return this.restService.get<any>(url, null, httpParams);
  }

  public setReportStolen(objReport: any): Observable<any> {    
    return this.restService.post<any>('/api/cardholders/cards/lost-stolen', objReport); 
  }

  public setReplace(objReplace: any): Observable<any> {
    return this.restService.post<any>('/api/cardholders/cards/replace', objReplace);
  } 

  public setUnLock(objUnLock: any): Observable<any> {    
    return this.restService.post<any>('/api/cardholders/cards/active', objUnLock);   
  }

  public setLock(objLock: any): Observable<any> {
    return this.restService.post<any>('/api/cardholders/cards/suspend', objLock); 
  }
  
  public getOrderNewCardData(cardKey) {
    let countryCode = this.appConfig.countryCode;
    const httpParams = new HttpParams() 
    .set('cardKey', cardKey.toString())
    .set('countryCode', countryCode.toString());        
    let url = "/api/cardholders/secondary-cards/new/metadata";
    return this.restService.get<any>(url, null, httpParams);
  }

  public orderNewCard(cardKey, objCard) {
    const httpParams = new HttpParams()
    .set('cardKey', cardKey.toString())   
    return this.restService.post<any>(`/api/cardholders/add-on`, objCard, null, httpParams);
  }

  public reloadCash(cardKey) {
    const httpParams = new HttpParams();    
    let url = `/api/reload-cash?cardKey=${cardKey}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public findATM(cardKey: number, language:string ): Observable<any> {
    const httpParams = new HttpParams();    
    let url = `/api/atm-locations/v2?cardKey=${cardKey.toString()}&language=${language}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public getAlerts(cardKey): Observable<any> {
    const httpParams = new HttpParams();    
    let url = `/api/cardholders/alerts/v2?CardKey=${cardKey}&read=true&unread=true&rowsPerPage=10&page=1`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public getAlertsWithPagination(cardKey, page): Observable<any> {
    const httpParams = new HttpParams();    
    let url = `/api/cardholders/alerts/v2?CardKey=${cardKey}&read=true&unread=true&rowsPerPage=10&page=${page}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public makeAlertsRead(cardKey, alerts): Observable<any> {
    return this.restService.post<any>(`/api/cardholders/alerts/read`, alerts);
  }

  public getESignAgreement(cardKey: number): Observable<any> {
    const httpParams = new HttpParams();    
    let url = `/api/cardholders/policy/latest?CardKey=${cardKey}&contentType=Esign`;
    return this.restService.get<any>(url, null, httpParams);
  }
  public getESignSavingAgreement(programKey: number, language: string): Observable<any> {
    const httpParams = new HttpParams();
    let url =`/api/programs/${programKey}/content?accountType=32&contentType=Esign&lang=${language}`
    return this.restService.get<any>(url, null, httpParams);
  }
  createSavingAccount(cardKey: number,postObj): Observable<any> {    
    return this.restService.post<any>(`/api/cardholders/create-savings?cardkey=${cardKey}`, postObj); 
  }
  
  public agree(request, cardKey: number): Observable<IESignAcceptRequest> {
    const httpParam: HttpParams = new HttpParams()
    .set('cardKey', cardKey.toString())
    return this.restService.post<IESignAcceptRequest>(`/api/cardholders/policy/accept`, request, null, httpParam);
  }

  public getsavingsCardHolderAgreement(programKey: number,language: string): Observable<any> {
    const httpParams = new HttpParams();    
    let url = `/api/programs/${programKey}/content?accountType=32&contentType=TnC&lang=${language}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public getCardholderAgreement(cardKey: number): Observable<any> {
    const httpParams = new HttpParams();    
    let url = `/api/cardholders/policy/latest?CardKey=${cardKey}&contentType=TnC`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public getTermsOfUse(cardKey: number): Observable<any> {
    const httpParams = new HttpParams();    
    let url = `/api/cardholders/policy/latest?CardKey=${cardKey}&contentType=CustomerTerms`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public getPrivacyPolicy(cardKey: number): Observable<any> {
    const httpParams = new HttpParams();    
    let url = `/api/cardholders/policy/latest?CardKey=${cardKey}&contentType=PrivacyPolicyv2`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public eSignOptOut(cardKey, request: any): Observable<any> {
    return this.restService.post<any>(`/api/cardholders/policy/opt-out?CardKey=${cardKey}`, request); 
  }

  public getMonthListForStatement(cardKey) {
    const httpParams = new HttpParams().set('cardKey', cardKey.toString())      
    let url = `/api/cardholders/statements/selections`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public downloadStatement(cardKey, documentId) { 
    let url = `${this.appConfig.baseUrl}/api/cardholders/statements/download?cardKey=${cardKey}&documentId=${documentId}`;
    return this.httpClient.get<Blob>(url,{responseType:'blob' as 'json'});
  }

  public getBillers(cardKey) {
    const httpParams = new HttpParams();      
    let url = `/api/cardholders/${cardKey}/billers`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public getScheduledPayments(cardKey) {
    const httpParams = new HttpParams();
    let url = `/api/cardholders/${cardKey}/bill-payments/scheduled`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public getPaymentHistory(cardKey, startDate, endDate, pageSize, CurrentPage) {
    const httpParams = new HttpParams();
    let url = `/api/cardholders/${cardKey}/bill-payments?cardKey=${cardKey}&endDate=${endDate}&startDate=${startDate}&rows=${pageSize}&page=${CurrentPage}`;
    return this.restService.get<any>(url, null, httpParams);
  }
  
  public getDirectDeposit(cardKey) {
    const httpParams = new HttpParams().set('cardKey', cardKey.toString())      
    let url = `/api/cardholders/dda`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public getDirectDepositPDFUri(cardKey) {
    const httpParams = new HttpParams().set('cardKey', cardKey.toString())      
    let url = `/api/cardholders/dda/pdf`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public downloadDirectDepositPDF(url) {
    return this.httpClient.get<Blob>(url, {responseType:'blob' as 'json'});
  }

  getCompletedAndPendingTransactions(itemRequest:ItemRequest, rowsPerPage:number,page:number){
    let httpParams = new HttpParams()
    //let url = `/api/cardholders/alerts?CardKey=${cardKey}&read=true&unread=true&rowsPerPage=10&page=${page}`;
    .set('cardKey', itemRequest.cardKey+'')
    .set('fromDate',itemRequest.fromDate.toLocaleDateString('en-CA'))
    .set('statusCode','STL,APP')
    .set('toDate',itemRequest.toDate.toLocaleDateString('en-CA'));
    if(rowsPerPage!==-1){
      httpParams = httpParams.set('rowsPerPage',rowsPerPage.toString())
    }

    if(itemRequest.activityDD!==''){

        switch (itemRequest.activityDD) {
          case 'spends':
            httpParams = httpParams.set('spends','true');
            httpParams = httpParams.set('loads','false');
            break;
          case 'loads':
            httpParams = httpParams.set('spends','false');
            httpParams = httpParams.set('loads','true');
            break;
        
          default:
            httpParams = httpParams.set('spends','true');
            httpParams = httpParams.set('loads','true');
            break;
        }
    }

    if(page!==-1){
      httpParams = httpParams.set('page',page.toString());
    }

    let url='/api/cardholders/transactions';
    return this.restService.get<any>(url, null, httpParams);
  }

  getCompletedTransactions(itemRequest:ItemRequest){
    const httpParams = new HttpParams()
    .set('cardKey', itemRequest.cardKey+'')
    .set('fromDate',itemRequest.fromDate.toLocaleDateString('en-CA'))
    .set('statusCode',itemRequest.statusCode)
    .set('toDate',itemRequest.toDate.toLocaleDateString('en-CA'));
    let url='/api/cardholders/transactions';
    return this.restService.get<any>(url, null, httpParams);
  }

  getPendingTransactions(itemRequest:ItemRequest){
    const httpParams = new HttpParams()
    .set('cardKey', itemRequest.cardKey+'')
    .set('fromDate',itemRequest.fromDate.toLocaleDateString('en-CA'))
    .set('statusCode',itemRequest.statusCode)
    .set('toDate',itemRequest.toDate.toLocaleDateString('en-CA'));
    let url='/api/cardholders/transactions';
    return this.restService.get<any>(url, null, httpParams);
  }

  getPendingFee(cardKey){
    const httpParams = new HttpParams()
    let url=`/api/cardholders/feetransactions?cardKey=${cardKey}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public getPayeesInfo(cardKey) {
    const httpParams = new HttpParams();      
    let url = `/api/cardholders/payees?cardKey=${cardKey}&includeAddons=true&includePayees=true&includePrimary=true`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public getPayeesHistory(cardKey, payeeID) {
    const httpParams = new HttpParams();      
    let url = `/api/cardholders/transactions?cardKey=${cardKey}&payeeID=${payeeID}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public transferFund(cardKey, data): Observable<any> {
    return this.restService.post<any>(`/api/cardholders/transfer-funds`, data); 
  }

  public getCards(): Observable<any> {
    return this.restService.get<any>("/api/cardholders/cards?addon=true&primary=true", null, null);
  }

  public getBillPaymentDetails(cardKey, SVAPaymentKey) {
    const httpParams = new HttpParams();      
    let url = `/api/cardholders/${cardKey}/bill-payments/details?SVAPaymentKey=${SVAPaymentKey}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public cancelScheduledPayment(cardKey, data): Observable<any> {
    return this.restService.post<any>(`/api/cardholder/${cardKey}/billpayment/cancel`, data); 
  }

  public getBillersAndScheduledPaymentCount(cardKey) {
    const httpParams = new HttpParams();      
    let url = `/api/cardholders/${cardKey}/billers/overview`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public getBillerDetails(cardKey, svaBillerKey) {
    const httpParams = new HttpParams();      
    let url = `/api/cardholders/${cardKey}/billers/details?svaBillerKey=${svaBillerKey}`;
    return this.restService.get<any>(url, null, httpParams);
  }
  
  public removeBiller(cardKey, data): Observable<any> {
    return this.restService.post<any>(`/api/cardholders/${cardKey}/billers/remove`, data); 
  }

  public getCountry(cardKey) {
    const httpParams = new HttpParams();      
    let url = `/api/cardholders/masters/countries?CardKey=${cardKey}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public getStates(cardKey, countryCode) {
    const httpParams = new HttpParams();      
    let url = `/api/cardholders/masters/countries/states?CardKey=${cardKey}&countryCode=${countryCode}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public fetchFrequencies(cardKey) {
    const httpParams = new HttpParams();      
    let url = `/api/cardholders/${cardKey}/billPayment/date/metadata`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public modifyPapperBillerDetails(cardKey, data): Observable<any> {
    return this.restService.post<any>(`/api/cardholders/${cardKey}/billers/modify-paper/v2`, data);  
  }

  public modifyRPPSBillerDetails(cardKey, data): Observable<any> {
    return this.restService.post<any>(`/api/cardholders/${cardKey}/billers/modify-rpps/v2`, data); 
  }

  public getBusinessTypes(cardKey) {
    const httpParams = new HttpParams();      
    let url = `/api/cardholders/${cardKey}/biller/businesstypes`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public searchBillers(cardKey, businessType, searchText = '', Alphabet = 0) {
    const httpParams = new HttpParams();      
    let url = `/api/cardholders/${cardKey}/masters/programs/billers?Alphabet=${Alphabet}&businessType=${businessType}&page=1&rows=100&searchText=${searchText}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public getSearchMetadata(cardKey) {
    const httpParams = new HttpParams();      
    let url = `/api/cardholders/${cardKey}/biller/search/metadata`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public addRPPSBiller(cardKey, data): Observable<any> {
      return this.restService.post<any>(`/api/cardholders/${cardKey}/billers/add-rpps/v2`, data); 
  }

  public addPapperBiller(cardKey, data): Observable<any> {
      return this.restService.post<any>(`/api/cardholders/${cardKey}/billers/add-paper/v2`, data); 
  }

  public getSpendAnalysis(cardKey, fromDate, toDate) {
    const httpParams = new HttpParams();
    let url = `/api/cardholders/spend-analysis/v2?cardKey=${cardKey}&fromDate=${fromDate}&toDate=${toDate}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public getSpendLoadData(cardKey: number, fromDate: string, toDate: string): Observable<SpendLoadResponse> {    
    const httpParams = new HttpParams() 
    .set('cardKey', cardKey.toString())
    .set('fromDate', fromDate)  
    .set('toDate',toDate);        
    let url = "/api/cardholders/spends-loads/monthly/v2";
    return this.restService.get<any>(url, null, httpParams);    
  }

  public getActivityMetaData(cardKey: number): Observable<any> {  
    const httpParams = new HttpParams() 
    .set('cardKey', cardKey.toString());
    let url = "/api/cardholder/activity/metadata";
    return this.restService.get<any>(url, null, httpParams);    
  }

  public getShippingData(cardKey:number):Observable<any>{
    const httpParams = new HttpParams()
    let url = `/api/cardholders/cards/${cardKey}/shipment-detail`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public feeSummary(cardKey: number): Observable<any> {  
    const httpParams = new HttpParams()
    let url = `/api/cardholders/fee-summary/${cardKey}`;
    return this.restService.get<any>(url, null, httpParams);    
  }

  public getCommunicationDetail(cardKey: number): Observable<any> {
    const httpParams = new HttpParams()
      .set('cardKey', cardKey.toString());
    let url = "/api/cardholders/communication/detail"
      return this.restService.get<any>(url, null, httpParams);
  }

  public generateOTP(cardKey, request: any): Observable<any> {
    if (cardKey==0)
      return this.restService.post<any>(`/api/cardholders/mobile/generate-otp?cardKey=${cardKey}`, request); 
    else if(cardKey != 0 && request)
      return this.restService.post<any>(`/api/cardholders/mobile/generate-otp?cardKey=${cardKey}`, request);
    else
      return this.restService.post<any>(`/api/cardholders/mobile/generate-otp?cardKey=${cardKey}`, null); 
  }

  public verifyOTP(cardKey, request: any): Observable<any> {
    return this.restService.post<any>(`/api/cardholders/mobile/verify-otp?CardKey=${cardKey}`, request); 
  }


  public getPayPerksLink(cardKey: number, version: string, isDarkMode:string, query:any=null) : Observable<any>{

    if (
      this.payperksCache[cardKey] !==
      undefined
    ) {
      return of(
        this.payperksCache[cardKey][
          'payperLinksResponse'
        ]
      );
    }

    const httpParams = new HttpParams() 
    .set('cardKey', cardKey.toString())  
    .set('isDarkMode', isDarkMode)
    .set('version', version);
    let url = "/api/cardholders/payperks/details";
    return this.restService.post<any>(url, { queryparams : query }, null, httpParams); 
  }
  
  public getPolicy(cardKey: number, contentType:string): Observable<any> {
    const httpParams = new HttpParams();    
    let url = `/api/cardholders/policy/latest/${cardKey}?contentType=${contentType}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public getNotificationOptin(cardKey: number, alertcode: string): Observable<any> {
    const httpParams = new HttpParams();
    let url = `/api/cardholders/cards/${cardKey}/alerts/opt-in?alertcode=${alertcode}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  public enableNotifications(cardKey: number): Observable<any> {
    let url = `/api/cardholders/cards/${cardKey}/enable-notifications?alertcode=O011`;
    return this.restService.post<any>(url, null);
  }

  public validatessn(cardKey, Postobj): Observable<any> {
    const httpParams = new HttpParams(); 
    return this.restService.post<any>(`/api/cardholders/cards/${cardKey.toString()}/validate-ssn`,Postobj);
  }

  public getPayPerksdashboard(cardKey, data) : Observable<any>{   
    return this.restService.post<any>(`/api/cardholders/payperks/dashboard?CardKey=${cardKey.toString()}`, data); 
  }

  ///ssn validate service new api

  public getSSNValidateStatus(cardKey, Postobj): Observable<any> {
    const httpParams = new HttpParams(); 
    return this.restService.post<any>(`/api/cardholders/cards/${cardKey.toString()}/ssn/validate`,Postobj);
  }

  
  ///reorder  api

  public ReorderCard(cardKey, Postobj): Observable<any> {
    const httpParams = new HttpParams(); 
    return this.restService.post<any>(`/api/cardholders/cards/${cardKey.toString()}/re-issue`,Postobj);
  }
  
}
