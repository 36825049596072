import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectedCardEvent } from '@services/message-bus/message-bus-events';
import { Card } from '@models/post-login.model';
import { MessageBusService } from '@services/message-bus/message-bus.service';
import { ThemeService } from '@services/theme.service';
import { DashboardService } from '../../pages/dashboard/dashboard.service';
import { AnalyticsService } from '@services/analytics.service';


@Component({
  selector: 'smione-warnings',
  templateUrl: './warnings.component.html',
  styleUrls: ['./warnings.component.scss']
})
export class WarningsComponent implements OnInit {
  public selectedCard: Card = {} as Card;
  count :number = 0;
  componentName : string = 'Warnings';
  constructor(
    public dialogRef: MatDialogRef<WarningsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dashboardService: DashboardService,
    public messageBus: MessageBusService,
    private themeService: ThemeService,
    private analyticsService: AnalyticsService
  ) { 
    this.messageBus.on(SelectedCardEvent, (event) => { this.selectedCard = event.payload });
  }

  ngOnInit() {
    this.themeService.SetThemeInitially();
    console.log(this.dialogData);
  }

  showNext(): void {
     if (this.count < this.dialogData.length - 1) {
         this.makeAlertRead(this.dialogData[this.count]);
    }
}


  makeAlertRead(alert){
    this.analyticsService.gtag(this.componentName, `makeAlertRead`);
    this.selectedCard.WarningShown = true;
    this.dashboardService.makeAlertsRead(this.selectedCard.CardKey, { cardKey: this.selectedCard.CardKey, AlertIds: [alert['ID']] }).subscribe(
      (res)=>{
        if(res['Response']['Success']){
          if((this.count+1) == this.dialogData.length){ this.close(true); }
          this.count++;
        }
      }
    );
  }

  close(flag?){
    this.analyticsService.gtag(this.componentName, `close`);
    this.selectedCard.WarningShown = true;
    this.dialogRef.close(flag); 
  }

}
